/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceCreation } from '../models/DeviceCreation';
import type { DeviceCreationResponse } from '../models/DeviceCreationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebRegistrationService {

    /**
     * Start adding a device for authentication.
     * @param requestBody
     * @returns DeviceCreationResponse Device Creation Successfully started
     * @throws ApiError
     */
    public static startAddDevice(
        requestBody?: DeviceCreation,
    ): CancelablePromise<DeviceCreationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/registerDevice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                409: `Device already exists`,
            },
        });
    }

}
