"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUtil = void 0;
const CommonUtils_1 = require("./CommonUtils");
class ArrayUtil {
    static cleanArray(input) {
        return input.filter(CommonUtils_1.isNotNull);
    }
    static removeIfFound(array, element) {
        const index = array.indexOf(element);
        if (index >= 0) {
            array.splice(index, 1);
        }
    }
    static flatten(array) {
        return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(val) : [...acc, val]), []);
    }
}
exports.ArrayUtil = ArrayUtil;
