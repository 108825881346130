import { Log, toStringSafe } from '@zorgi-mobile/shared-javascript';
import axios from 'axios';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

export interface WebEnvironment {
    apiUrl: string;
    clientId: string;
    tenantId: string;
    scope: string;
}

export const defaultWebEnvironment = {
    apiUrl: `${window.location.protocol}//${window.location.hostname}:8080/v1`,
    clientId: 'bab30a1a-ae15-4ad1-829e-acf334a57247',
    tenantId: 'c32cd569-e121-4cb9-b835-6e299a75b32c',
    scope: '',
};

export interface EnvContextType {
    env: WebEnvironment;
    loadingEnv: boolean;
}

const defaultEnvContext = {
    env: defaultWebEnvironment,
    loadingEnv: true,
};

export const EnvContext = createContext<EnvContextType>(defaultEnvContext);

export const EnvProvider: FC = function EnvProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [env, setEnv] = useState(defaultWebEnvironment);

    useEffect(() => {
        const readEnv = async () => {
            setLoading(true);
            const success = await loadEnv('./env.json');
            if (!success && process.env.NODE_ENV === 'development') {
                await loadEnv('./env.dev.json');
            }
            setLoading(false);
        };
        readEnv();
    }, []);

    async function loadEnv(envPath: string): Promise<boolean> {
        try {
            const response = await axios.get(envPath);
            if (response && response.data) {
                const {
                    API_URL, CLIENT_ID, TENANT_ID, SCOPE,
                } = response.data;
                if (!API_URL || !CLIENT_ID || !TENANT_ID || !SCOPE) {
                    return false;
                }
                setEnv({
                    apiUrl: API_URL,
                    clientId: CLIENT_ID,
                    tenantId: TENANT_ID,
                    scope: SCOPE,
                });
                return true;
            }
        } catch (e) {
            Log.error('readEnv', toStringSafe(e));
        }
        return false;
    }

    const value = useMemo(() => ({
        env,
        loadingEnv: loading,
    }), [env, loading]);

    return (
        <EnvContext.Provider value={value}>
            {children}
        </EnvContext.Provider>
    );
};
