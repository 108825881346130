"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidDate = exports.compareDates = exports.isTodayDate = exports.isoToDayMonthYearFormat = exports.transformDateFormat = exports.formatDate = exports.findClosestTimestampToToday = exports.minDay = exports.convertDateToTimestamp = exports.subtractMinutesFromGivenDate = exports.subtractDaysFromCurrentDate = exports.parseDate = exports.DateFormat = void 0;
const date_fns_1 = require("date-fns");
const translation_1 = require("../translation");
var DateFormat;
(function (DateFormat) {
    DateFormat["iso8601"] = "yyyy-MM-dd'T'HH:mm:ss'Z'";
    DateFormat["dayMonthYear"] = "dd/MM/yyyy";
    DateFormat["yearMonthDay"] = "yyyy/MM/dd";
    DateFormat["hourMinute"] = "HH:mm";
    DateFormat["timeStamp"] = "t";
    DateFormat["dayMonthYearHourMinute"] = "dd/MM/yyyy HH:mm";
})(DateFormat = exports.DateFormat || (exports.DateFormat = {}));
/**
 * expect a date string and make it a date object
 * @param dateString date string
 * @param sourcePattern date string pattern
 * @returns date object
 */
function parseDate(dateString, sourcePattern = DateFormat.iso8601) {
    return (0, date_fns_1.parse)(dateString, sourcePattern, new Date());
}
exports.parseDate = parseDate;
/**
 * expect a number of the number of days to remove and returns the subtracted date
 * @param numberOfDays date number
 * @returns date object
 */
function subtractDaysFromCurrentDate(numberOfDays) {
    return (0, date_fns_1.subDays)(new Date(), numberOfDays);
}
exports.subtractDaysFromCurrentDate = subtractDaysFromCurrentDate;
/**
 * expect a number of minutes to remove, a date object and returns the subtracted date
 * @param minutes number
 * @param givenDate date object
 * @returns date object
 */
function subtractMinutesFromGivenDate(minutes, givenDate) {
    return (0, date_fns_1.subMinutes)(givenDate, minutes);
}
exports.subtractMinutesFromGivenDate = subtractMinutesFromGivenDate;
/**
 * expect a date and returns timestamp
 * @param date date
 * @returns timestamp number
 */
function convertDateToTimestamp(date) {
    return (0, date_fns_1.getTime)(date);
}
exports.convertDateToTimestamp = convertDateToTimestamp;
/**
 * expect an array of numbers, finds and returns the minimum number (timestamp) inside the array
 * @param timestamps numbers array
 * @returns number
 */
function minDay(timestamps) {
    return (0, date_fns_1.getTime)((0, date_fns_1.min)(timestamps));
}
exports.minDay = minDay;
/**
 * expect two numbers (timestamps) , finds and returns the timestamp that is closest to today
 * @param timestampA number
 * @param timestampB number
 * @returns number
 */
function findClosestTimestampToToday(timestampA, timestampB) {
    const today = convertDateToTimestamp(new Date());
    const distanceA = Math.abs(timestampA - today);
    const distanceB = Math.abs(timestampB - today);
    if (distanceA <= distanceB) {
        return timestampA;
    }
    return timestampB;
}
exports.findClosestTimestampToToday = findClosestTimestampToToday;
/**
 * expect a date object and format it to the desired pattern
 * @param date date object
 * @param targetPattern pattern wanted for the date
 * @returns string with a formatted date
 */
function formatDate(date, targetPattern = DateFormat.iso8601) {
    // TODO: Check if we need to implement sever time offset correction
    if (!(0, date_fns_1.isValid)(date)) {
        return translation_1.TranslateUtil.translateKey('invalid_date');
    }
    return (0, date_fns_1.format)(date, targetPattern);
}
exports.formatDate = formatDate;
/**
 * expect a date string and format it to the desired pattern
 * @param dateString date string
 * @param sourcePattern date string pattern
 * @param targetPattern pattern wanted for the date
 * @returns string with a formatted date
 */
function transformDateFormat(dateString, sourcePattern = DateFormat.iso8601, targetPattern = DateFormat.iso8601) {
    return formatDate(parseDate(dateString, sourcePattern), targetPattern);
}
exports.transformDateFormat = transformDateFormat;
/**
 * expect a date iso string and format it to a dayMonthYear string
 * @param dateString iso date string
 * @returns string with a dayMonthYear formatted date
 */
function isoToDayMonthYearFormat(isoDateString) {
    return transformDateFormat(isoDateString, DateFormat.iso8601, DateFormat.dayMonthYear);
}
exports.isoToDayMonthYearFormat = isoToDayMonthYearFormat;
/**
 * check if a date is the same as today's
 * @param date date object
 * @returns boolean
 */
function isTodayDate(date) {
    return (0, date_fns_1.isToday)(date);
}
exports.isTodayDate = isTodayDate;
/**
 * compare two dates following a dir
 * @param dateA date object
 * @param dateB date object
 * @returns number
 */
function compareDates(dateA, dateB, dir = 'asc') {
    return dir === 'asc' ? (0, date_fns_1.compareAsc)(dateA, dateB) : (0, date_fns_1.compareDesc)(dateA, dateB);
}
exports.compareDates = compareDates;
/**
 * check if a date object is a valid date
 * @param date date object
 * @returns Date
 */
function isValidDate(date) {
    return (0, date_fns_1.isValid)(date);
}
exports.isValidDate = isValidDate;
