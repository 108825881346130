import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';

import { DeviceCreationResponse, DeviceState, WebManagementService, WebRegistrationService } from '../api/generated';
import { useCustomer } from '../context/CustomerContext';

interface AddDeviceDialogProps {
    isOpen: boolean;
    onClose: Function;
}

const maxDeviceNameLength = 255;

export function AddDeviceDialog(props: AddDeviceDialogProps) {
    const [deviceName, setDeviceName] = useState('');
    const [error, setError] = useState<string | null>(null);
    const customerContext = useCustomer();
    const [deviceCreationResponse, setDeviceCreationResponse] = useState<DeviceCreationResponse | null>(null);

    const isDeviceRegistered = async (resp: DeviceCreationResponse) => {
        const device = await WebManagementService.getDeviceState(resp.device.id);
        return device.state === DeviceState.REGISTERED;
    };

    const deviceStateListenFunction = (resp: DeviceCreationResponse) => {
        setTimeout(async () => {
            const registered = await isDeviceRegistered(resp);
            if (registered) {
                props.onClose();
                setDeviceName('');
                setDeviceCreationResponse(null);
                setError(null);
            } else {
                deviceStateListenFunction(resp);
            }
        }, 2000);
    };

    const doDeviceCreateRequest = () => {
        WebRegistrationService.startAddDevice({
            customer: customerContext.customer?.id,
            name: deviceName,
        }).then((newDeviceCreationResponse) => {
            setDeviceCreationResponse(newDeviceCreationResponse);
            deviceStateListenFunction(newDeviceCreationResponse);
        }).catch((e) => {
            if (e.status === 409) {
                setError(TranslateUtil.translateKey('device_already_exists'));
            } else {
                setError(e.message);
            }
        });
    };

    return (
        <Dialog isOpen={props.isOpen}>
            <div className={Classes.DIALOG_HEADER}>
                <h1>{TranslateUtil.translateKey('add_mobile_device')}</h1>
            </div>
            <div className={Classes.DIALOG_BODY}>
                {!deviceCreationResponse ? (
                    <form
                        style={{ display: 'flex', flexDirection: 'column' }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (deviceName.length === 0) {
                                setError('required');
                                return;
                            }
                            if (deviceName.length > maxDeviceNameLength) {
                                setError('too long');
                                return;
                            }
                            doDeviceCreateRequest();
                        }}
                    >
                        <FormGroup
                            intent={Intent.DANGER}
                            helperText={error || (deviceName.length > maxDeviceNameLength ? TranslateUtil.translateKey('devicename_too_long') : null)}
                            label={TranslateUtil.translateKey('enter_device_name')}
                            labelFor="device-name-input"
                            labelInfo={`(${TranslateUtil.translateKey('required')})`}
                        >
                            <InputGroup
                                onChange={(ev) => {
                                    setDeviceName(ev.target.value);
                                }}
                                id="device-name-input"
                                placeholder={TranslateUtil.translateKey('enter_device_name')}
                                maxLength={maxDeviceNameLength}
                                required
                                autoFocus
                            />
                        </FormGroup>
                        <Button
                            type="submit"
                            style={{ alignSelf: 'flex-end' }}
                        >
                            {TranslateUtil.translateKey('add')}
                        </Button>
                    </form>

                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <h2>{TranslateUtil.translateKey('scan_this_qr_code_on_device')}</h2>
                        <QRCode value={JSON.stringify({
                            secret: deviceCreationResponse.secret,
                            deviceId: deviceCreationResponse.device.id,
                            deviceName: deviceCreationResponse.device.name,
                            deviceToken: deviceCreationResponse.device.token,
                            environment: deviceCreationResponse.environment,
                        })}
                        />
                    </div>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <Button onClick={() => {
                    setDeviceName('');
                    setDeviceCreationResponse(null);
                    setError(null);
                    props.onClose();
                }}
                >
                    {TranslateUtil.translateKey('close')}
                </Button>
            </div>

        </Dialog>
    );
}
