/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerConfig } from '../models/CustomerConfig';
import type { CustomerConfigCreation } from '../models/CustomerConfigCreation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebConfigService {

    /**
     * Return a config file for the customer of the logged in user/device
     * @param customer
     * @returns CustomerConfig OK
     * @throws ApiError
     */
    public static getConfigWeb(
        customer: string,
    ): CancelablePromise<CustomerConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/config/{customer}',
            path: {
                'customer': customer,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Sets a config file for the customer. If customer name does not exist, a new customer is created
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setConfig(
        requestBody?: CustomerConfigCreation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/config/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
