"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertsColorUtil = void 0;
/**
 * Colors only used in XCare Alerts (Avertissements)
 */
class AlertsColorUtil {
    static getPatientPanelRed() { return 'rgb(255, 242, 242)'; }
}
exports.AlertsColorUtil = AlertsColorUtil;
