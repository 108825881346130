/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerList } from '../models/CustomerList';
import type { Device } from '../models/Device';
import type { DevicesList } from '../models/DevicesList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebManagementService {

    /**
     * Get device information
     * @param deviceId
     * @returns Device Return state information
     * @throws ApiError
     */
    public static getDeviceState(
        deviceId: string,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Device not found`,
            },
        });
    }

    /**
     * Returns a list of all devices for the specific customer
     * @param customer
     * @returns DevicesList OK
     * @throws ApiError
     */
    public static getDevicesList(
        customer: string,
    ): CancelablePromise<DevicesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/devices/{customer}',
            path: {
                'customer': customer,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Gets a list of all customers in the system
     * @returns CustomerList OK
     * @throws ApiError
     */
    public static getCustomers(): CancelablePromise<CustomerList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/customers/',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Adds a customer into the system
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addCustomer(
        requestBody?: Customer,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/customers/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
