import { Button, Intent, Spinner } from '@blueprintjs/core';
import { Cell, Column, Table } from '@blueprintjs/table';
import { TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DevicesList, OpenAPI, WebManagementService } from '../api/generated';
import { AddDeviceDialog } from '../components/AddDeviceDialog';
import { CustomerSelectHOC } from '../components/CustomerSelectHOC';
import { LogisticsNavbar } from '../components/Navbar';
import { useCustomer } from '../context/CustomerContext';

const renderTable = (devices: DevicesList) => {
    const idCellRenderer = (rowIndex: number) => (
        <Cell>{devices[rowIndex].id}</Cell>
    );
    const nameCellRenderer = (rowIndex: number) => (
        <Cell>{devices[rowIndex].name}</Cell>
    );
    const stateCellRenderer = (rowIndex: number) => (
        <Cell>{devices[rowIndex].state}</Cell>
    );

    return (
        <div style={{ margin: 10 }}>
            <Table numRows={devices.length}>
                <Column name={TranslateUtil.translateKey('id')} cellRenderer={idCellRenderer} />
                <Column name={TranslateUtil.translateKey('name')} cellRenderer={nameCellRenderer} />
                <Column name={TranslateUtil.translateKey('state')} cellRenderer={stateCellRenderer} />
            </Table>
        </div>

    );
};

export function DevicesOverviewScreen() {
    const [devices, setDevices] = useState<DevicesList | null>(null);
    const [isAddingDevice, setAddingDevice] = useState(false);
    const { customer } = useCustomer();
    const navigate = useNavigate();

    useEffect(() => {
        if (!OpenAPI.TOKEN && process.env.REACT_APP_NOAUTH !== 'true') {
            navigate('/');
        }
    }, []);

    const loadData = (customerId?: string) => {
        WebManagementService.getDevicesList(customerId || (customer ? customer.id : ''))
            .then((newDevices) => {
                setDevices(newDevices);
            });
    };

    if (devices === null && customer !== null) {
        loadData();
    }

    return (
        <div>
            <LogisticsNavbar
                onCustomerChange={(customerId: string) => {
                    loadData(customerId);
                }}
                active="devices"
            />
            <CustomerSelectHOC>
                <div style={{ width: '80%', margin: 'auto' }}>
                    <h1>{TranslateUtil.translateKey('mobile_devices')}</h1>
                    <Button
                        onClick={() => setAddingDevice(true)}
                        intent={Intent.PRIMARY}
                        icon="add"
                        text={TranslateUtil.translateKey('add_mobile_device')}
                    />
                    <AddDeviceDialog
                        isOpen={isAddingDevice}
                        onClose={() => {
                            loadData();
                            setAddingDevice(false);
                        }}
                    />
                    {devices
                        ? (renderTable(devices))
                        : (<div style={{ marginTop: 20 }}><Spinner /></div>)}
                </div>
            </CustomerSelectHOC>
        </div>

    );
}
