import { Button, Intent, Spinner } from '@blueprintjs/core';
import { Log, toStringSafe, TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OpenAPI, WebManagementService } from '../api/generated';
import { AddCustomerDialog } from '../components/AddCustomerDialog';
import { CustomerTable } from '../components/CustomerTable';
import { LogisticsNavbar } from '../components/Navbar';
import { useCustomer } from '../context/CustomerContext';

export function CustomersOverviewScreen() {
    const [isAddingCustomer, setAddingCustomer] = useState(false);
    const { availableCustomers, setAvailableCustomers } = useCustomer();
    const navigate = useNavigate();

    const loadData = useCallback(async () => {
        try {
            const newCustomers = await WebManagementService.getCustomers();
            setAvailableCustomers(newCustomers);
        } catch (e) {
            Log.warn('Failed to get customers', toStringSafe(e));
        }
    }, []);

    useEffect(() => {
        if (!OpenAPI.TOKEN && process.env.REACT_APP_NOAUTH !== 'true') {
            navigate('/');
        } else if (availableCustomers == null) {
            loadData();
        }
    }, [
        availableCustomers,
        loadData,
        navigate,
    ]);

    return (
        <div>
            <LogisticsNavbar active="customers" />
            <div style={{
                width: '80%',
                margin: 'auto',
            }}
            >
                <h1>
                    {TranslateUtil.translateKey('customers')}
                </h1>
                <Button
                    onClick={() => setAddingCustomer(true)}
                    intent={Intent.PRIMARY}
                    icon="add"
                    text={TranslateUtil.translateKey('add_customer')}
                />
                <AddCustomerDialog
                    isOpen={isAddingCustomer}
                    onClose={() => {
                        loadData();
                        setAddingCustomer(false);
                    }}
                />
                {availableCustomers
                    ? <CustomerTable customers={availableCustomers} />
                    : (
                        <div style={{ marginTop: 20 }}>
                            <Spinner />
                        </div>
                    )}
            </div>
        </div>
    );
}
