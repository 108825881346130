"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActsColorUtil = void 0;
/**
 * Colors only used in XCare Acts
 */
class ActsColorUtil {
    static getAddProduct() { return 'rgb(0, 161, 159)'; }
}
exports.ActsColorUtil = ActsColorUtil;
