import { useMsal } from '@azure/msal-react';
import { Alignment, Button, Classes, Dialog, Navbar } from '@blueprintjs/core';
import { Log, toStringSafe, TranslateUtil } from '@zorgi-mobile/shared-javascript';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { WebManagementService } from '../api/generated';
import image from '../assets/Zorgi.png';
import { useCustomer } from '../context/CustomerContext';

import { CustomerSelection } from './CustomerSelection';

interface LogisticsNavbarProps {
    active: string;
    onCustomerChange?: Function;
}

const isActive = ({ active }: { active: string }, screen: string) => active === screen;

export function LogisticsNavbar({ active, onCustomerChange }: LogisticsNavbarProps) {
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const { customer, setCustomer, availableCustomers, setAvailableCustomers } = useCustomer();

    const { instance } = useMsal();

    const loadCustomers = async () => {
        try {
            const newCustomers = await WebManagementService.getCustomers();
            setAvailableCustomers(newCustomers);
        } catch (e) {
            Log.warn(toStringSafe(e));
        }
    };

    useEffect(() => {
        loadCustomers();
    }, []);

    const setSelectedCustomer = (event: any) => {
        if (event.target.value === 'default') {
            setCustomer(undefined);
        } else {
            const newCustomer = availableCustomers.find((c) => (c.id === event.target.value));
            setCustomer(newCustomer);
            requestAnimationFrame(() => {
                if (onCustomerChange) {
                    onCustomerChange(customer?.id);
                }
            });
        }
    };

    return (
        <Navbar>
            <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading><img alt="logo" style={{ height: 30 }} src={image} /></Navbar.Heading>
                <Navbar.Heading>Logistics</Navbar.Heading>
                <Navbar.Divider />
                <Link to="/customers">
                    <Button
                        active={isActive({ active }, 'customers')}
                        className="bp4-minimal"
                        icon="list"
                        text={TranslateUtil.translateKey('customers')}
                    />
                </Link>
                <Link to="/devices">
                    <Button
                        active={isActive({ active }, 'devices')}
                        className="bp4-minimal"
                        icon="mobile-phone"
                        text={TranslateUtil.translateKey('mobile_devices')}
                    />
                </Link>
                <Link to="/config">
                    <Button
                        active={isActive({ active }, 'config')}
                        className="bp4-minimal"
                        icon="cog"
                        text={TranslateUtil.translateKey('config')}
                    />
                </Link>
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
                <select
                    onChange={setSelectedCustomer}
                    style={{ marginRight: 5 }}
                >
                    <CustomerSelection />
                </select>
                <Button onClick={() => {
                    setShowLogoutDialog(true);
                }}
                >
                    {TranslateUtil.translateKey('logout')}
                </Button>
            </Navbar.Group>

            <Dialog isOpen={showLogoutDialog}>
                <div className={Classes.DIALOG_HEADER}>
                    <h1>{TranslateUtil.translateKey('logout')}</h1>
                </div>
                <div className={Classes.DIALOG_BODY}>
                    <p>{TranslateUtil.translateKey('are_you_sure_logout')}</p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => {
                            setShowLogoutDialog(false);
                            instance.logout();
                        }}
                        >
                            {TranslateUtil.translateKey('yes')}
                        </Button>
                        <Button onClick={() => {
                            setShowLogoutDialog(false);
                        }}
                        >
                            {TranslateUtil.translateKey('no')}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Navbar>
    );
}
